<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div class="d-flex justify-space-between flex-wrap">
          <v-btn
            class="ma-2"
            dark
            to="/patner-form"
            color="success">
            <v-icon>mdi-plus</v-icon>
            Add Partner
          </v-btn>
          <!-- <div>
              <v-btn class="ma-2" color="primary">
                  <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn outlined class="ma-2">Import</v-btn>
              <v-btn outlined class="ma-2">Export</v-btn>
          </div> -->
        </div>
        <v-card-title>
          Partners
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="patners"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.country="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.country.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.bpartnerId="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="partnerProfile(item.bpartnerId)"
                    icon
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Profile</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.iscustomer="{item}">
            <template v-if="item.iscustomer">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.isvendor="{item}">
            <template v-if="item.isvendor">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.issalesrep="{item}">
            <template v-if="item.issalesrep">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    data() {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: 'Patner',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'City', value: 'city' },
          { text: 'Country', value: 'country' },
          { text: 'Customer', value: 'iscustomer' },
          { text: 'Vendor', value: 'isvendor' },
          { text: 'Sales Rep', value: 'issalesrep' },
          { text: 'Action', value: 'bpartnerId' },
        ],
      }
    },
    mounted () {
      this.fetchPatners()
    },
    computed: {
      ...mapGetters(['patners']),
    },
    methods: {
      ...mapActions(['fetchPatners', 'viewPartner']),
      partnerById (id) {
        let partner = null
        this.patners.forEach(element => {
          if (element.bpartnerId === id) {
            partner = element
          }
        });
        return partner
      },
      partnerProfile (id) {
        let partner = this.partnerById(id)

        if (partner) {
          this.viewPartner(partner)
          this.$router.push('/patner')
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
